import type { CardDisposition, ContentAlignement } from '~/types/design-system'

export const getContentAlignment = (layout: number): ContentAlignement => {
  let contentAlignment: ContentAlignement = 'left'
  if (layout === 2) {
    contentAlignment = 'center'
  } else if (layout === 3) {
    contentAlignment = 'right'
  }

  return contentAlignment
}

export const getContentAlignmentResponsive = (layout: number): CardDisposition => {
  let contentAlignment: CardDisposition = 'topOrLeft'
  if (layout === 2) {
    contentAlignment = 'center'
  } else if (layout === 3) {
    contentAlignment = 'bottomOrRight'
  }

  return contentAlignment
}

export const isCarouselLayout = (layout: number): boolean => {
  return layout === 2 // CarouselLayout
}
